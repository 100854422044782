.btn-rounded {
    border-radius: 50%;
    width: 34px;
    height: 34px;
    line-height: 36px;
    padding: 0;
}

.btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.428571429;
}

.icon-circle {
  width: 24px;
  height: 24px;
  display: inline-block;
  padding: 0;
  line-height: 24px;
  font-size: 22px;
  border-radius: 50%;
}

.width-100 {
    width: 100px;
}

.item-list {
  list-style: outside none none;
  margin: 0;
  padding: 0;
  li{
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: #fff;
    border-color: #ddd;
    border-image: none;
    border-style: solid;
    border-width: 1px 1px 1px 3px;
    margin-top: -1px;
    padding: 9px;
    position: relative;
    label{
      font-size: 13px;
    }
    .selected {
      background-color: #f4f9fc;
      color: #8090a0;
      label, .lbl{
        color: #8090a0;
        text-decoration: line-through;
        .inline{
          display: inline-block;
        }
      }
    }
  }
  li:nth-child(1) {
    border-left-color: #80f31f;
  }
  li:nth-child(2) {
    border-left-color: #a5de0b;
  }
  li:nth-child(3) {
    border-left-color: #c7c101;
  }
  li:nth-child(4) {
    border-left-color: #e39e03;
  }
  li:nth-child(5) {
    border-left-color: #f6780f;
  }
  li:nth-child(6) {
    border-left-color: #fe5326;
  }
  li:nth-child(7) {
    border-left-color: #fb3244;
  }
  li:nth-child(8) {
    border-left-color: #ed1868;
  }
  li:nth-child(9) {
    border-left-color: #b601b3;
  }
  li:nth-child(10) {
    border-left-color: #9106d3;
  }
  li:nth-child(11) {
    border-left-color: #6b16ec;
  }
  li:nth-child(12) {
    border-left-color: #472ffa;
  }
  li:nth-child(13) {
    border-left-color: #2850fe;
  }
  li:nth-child(14) {
    border-left-color: #1175f7;
  }
  li:nth-child(15) {
    border-left-color: #039be5;
  }
  li:nth-child(16) {
    border-left-color: #01beca;
  }
}

.right-charts {
  height: 195px;
}

#world-map {
  height: 700px;
}

.box-special {
  background: #f9f9f9 none repeat scroll 0 0;
  margin: 150px auto;
  text-align: center;
}

.tab-search {
    padding-bottom: 5px;
    margin-bottom: 10px;
}

.panel-profile .image img {
    width: 100px;
    border: 1px solid #dfdfdf
}

.panel-profile .name {
    text-align: center;
    margin-top: 10px;
    font-size: 16px
}

.panel-profile .icons {
    margin-top: 20px
}

.panel-profile .icons .btn {
    margin-right: 8px
}

.panel-profile table {
    text-align: left
}

.avatar-source,.avatar-wrapper,.btn-upload {
    text-align: center
}

.avatar-source div:hover,.avatar-source img:hover {
    background-color: #fff;
    border: 2px solid #555;
    cursor: pointer
}

.avatar-source p {
    margin: 5px 0 0;
    font-weight: 700
}

@media (min-width: 991px) {
    .avatar-wrapper .avatar-preview {
        margin-top:55px;
        margin-bottom: 80px
    }
}

@media (max-width: 991px) {
    .avatar-wrapper .avatar-preview {
        margin-bottom:20px
    }
}


.avatar-controls {
    display: none
}

#avatar.croppie-container {
    padding: 35px 30px 30px;
}

.spinner {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transition: opacity 1s;
    -moz-transition: opacity 1s;
    -o-transition: opacity 1s;
    -ms-transition: opacity 1s;
    transition: opacity 1s;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
}

.spinner>.spinner-dot:nth-child(1) {
    -webkit-animation-delay: -.32s;
    -moz-animation-delay: -.32s;
    -o-animation-delay: -.32s;
    -ms-animation-delay: -.32s;
    animation-delay: -.32s
}

.spinner>.spinner-dot:nth-child(2) {
    -webkit-animation-delay: -.16s;
    -moz-animation-delay: -.16s;
    -o-animation-delay: -.16s;
    -ms-animation-delay: -.16s;
    animation-delay: -.16s
}

.spinner>.spinner-dot {
    width: 15px;
    height: 15px;
    background: #333;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    display: inline-block;
    margin: 0 5px;
    -webkit-animation: bounce 1.4s infinite ease-in-out;
    -moz-animation: bounce 1.4s infinite ease-in-out;
    -o-animation: bounce 1.4s infinite ease-in-out;
    -ms-animation: bounce 1.4s infinite ease-in-out;
    animation: bounce 1.4s infinite ease-in-out;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    animation-fill-mode: both
}

@-moz-keyframes bounce {
    0%,100%,80% {
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -o-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0)
    }

    40% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -o-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1)
    }
}

@-webkit-keyframes bounce {
    0%,100%,80% {
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -o-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0)
    }

    40% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -o-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1)
    }
}

@-o-keyframes bounce {
    0%,100%,80% {
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -o-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0)
    }

    40% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -o-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes bounce {
    0%,100%,80% {
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -o-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0)
    }

    40% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -o-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1)
    }
}

.btn-upload input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: 0;
    background: #fff;
    cursor: inherit;
    display: block
}

.avatar-preview {
    width: 160px;
    height: 160px
}

.avatar-source img,.btn-upload {
    width: 120px
}

.btn-avatar {
    padding: 10px 0;
    border: 1px solid #dfdfdf;
    margin-top: 10px;
    transition: all .5s ease 0s
}

.btn-avatar:hover {
    background-color: #000;
    border-color: #000;
    opacity: .8;
    color: #fff;
    cursor: pointer
}

.btn-upload {
    height: 120px;
    padding: 6px 0;
    border-radius: 50%;
    font-size: 65px;
    color: #ccc;
    position: relative;
    overflow: hidden
}

.avatar-source div:hover,.avatar-source img:hover {
    background-color: #fff;
    border: 2px solid #555;
    cursor: pointer
}

.avatar-source p {
    margin: 5px 0 0;
    font-weight: 700
}

.avatar-controls {
    display: none
}

.btn-skype {
    color: #fff;
    background-color: #01AEF2;
    border-color: rgba(0,0,0,.2);
}

.btn-dribbble {
    color: #fff;
    background-color: #F46899;
    border-color: rgba(0,0,0,.2);
}

.btn-skype:focus,
.btn-skype.focus {
  color: #ffffff;
  background-color: #00c0ef;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-skype:hover {
  color: #ffffff;
  background-color: #00c0ef;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-skype:active,
.btn-skype.active,
.open > .dropdown-toggle.btn-skype {
  color: #ffffff;
  background-color: #00c0ef;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-skype:active,
.btn-skype.active,
.open > .dropdown-toggle.btn-skype {
  background-image: none;
}

.btn-dribbble:focus,
.btn-dribbble.focus {
  color: #ffffff;
  background-color: #E76391;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-dribbble:hover {
  color: #ffffff;
  background-color: #E76391;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-dribbble:active,
.btn-dribbble.active,
.open > .dropdown-toggle.btn-dribbble {
  color: #ffffff;
  background-color: #E76391;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-dribbble:active,
.btn-dribbble.active,
.open > .dropdown-toggle.btn-dribbble {
  background-image: none;
}

.dashboard-panel {
    color: #444;
}

.dashboard-panel:hover {
    text-decoration: none;
}

.dashboard-panel .icon {
    text-align: center;
    font-size: 36px;
}

.dashboard-panel .lead {
    text-align: center;
    font-size: 18px;
    margin-bottom: 10px;
}

.dashboard-panel:hover {
    color: #337ab7;
    background-color: #fbfbfb;
}